import { useState, useEffect } from 'react'
import isMinViewport from '../utils/is-min-viewport'

export default function useIsMobile () {
  const [mobile, setMobile] = useState(isMinViewport())

  useEffect(() => {
    const onResize = () => setMobile(isMinViewport())

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return mobile
}
